import React, { useState } from "react";


import resonanzteam from '../soclogos/resonanzteam.jpeg'
import junoon from '../soclogos/junoon.png'

//import lantern from '../images/lantern.jpeg'
import resoreelchallange from'../images/resoreelchallange.png'
import resoroid from '../images/resoroid.jpeg'
import garbanight from '../images/garbanight.jpeg'
import contest from '../images/contest.jpeg'

import "./DummyCard2.css";


/* <div className='Card'>
    <div className='Description'>
        <img src={resonanzteam} alt='' style={{ height: "40px", width: "40px" }} />
        <div className='Description-Text'>
            <h3>The Resonanz Team</h3>
            <p>6th Block Terrace</p>
        </div>
    </div>

    <div className='Poster_Container'>
        <img src={lantern} alt='' style={{ height: "240px", width: "100%" }} />
    </div>

    <div className='Footer-Part'>
      <p className='Registration-Misc'>No Registration</p>
      <p>To Be Declared</p>
    </div>
</div> */

function DummyCard0() {

  return (
    <>

<div className='Card-Container'>

<div className='Card'>
    <div className='Description'>
        <img className='white-logos' src={resonanzteam} alt='' style={{ height: "40px", width: "40px", }} />
        <div className='Description-Text'>
            <h3>The Resonanz Team</h3>
            <p>Main Campus</p>
        </div>
    </div>

    <div className='Poster_Container'>
        <img src={contest} alt='' style={{ height: "240px", width: "100%" }} />
    </div>

    <div className='Footer-Part'>
      <a target="_blank" href='https://forms.gle/TWrKdgMZFCwy8b5d6'><button className='Registration-Link'>Check Out!</button></a>
    </div>
</div>

<div className='Card'>
    <div className='Description'>
        <img className='white-logos' src={resonanzteam} alt='' style={{ height: "40px", width: "40px", }} />
        <div className='Description-Text'>
            <h3>The Resonanz Team</h3>
            <p>Main Campus</p>
        </div>
    </div>

    <div className='Poster_Container'>
        <img src={garbanight} alt='' style={{ height: "240px", width: "100%" }} />
    </div>

    <div className='Footer-Part'>
      <a target="_blank" href='https://docs.google.com/forms/u/7/d/1tKrdUgE-XiFyMv6IgLECnlhw5mrNswOvmOLd3jLLiAM/edit?usp=sharing_eil_se_dm&ts=6341638a'><button className='Registration-Link'>Register Here!</button></a>
      <p>Day 0 Evening</p>
    </div>
</div>

<div className='Card'>
    <div className='Description'>
        <img src={resonanzteam} alt='' style={{ height: "40px", width: "40px", }} />
        <div className='Description-Text'>
            <h3>The Resonanz Team</h3>
            <p>Main Campus</p>
        </div>
    </div>

    <div className='Poster_Container'>
        <img src={resoreelchallange} alt='' style={{ height: "240px", width: "100%" }} />
    </div>

    <div className='Footer-Part'>
      <a target="_blank" href='https://docs.google.com/forms/d/e/1FAIpQLSezaZypVY-bz6LiN3cUlUuBL4BXTRWnGVczniwhqy9T0Y6ePQ/viewform'><button className='Registration-Link' href=''>Register Here</button></a>
      <p>To Be Declared</p>
    </div>
</div>

<div className='Card'>
    <div className='Description'>
        <img className='white-logos' src={junoon} alt='' style={{ height: "40px", width: "40px", }} />
        <div className='Description-Text'>
            <h3>The Resonanz Team</h3>
            <p>Main Campus</p>
        </div>
    </div>

    <div className='Poster_Container'>
        <img src={resoroid} alt='' style={{ height: "240px", width: "100%" }} />
    </div>

    <div className='Footer-Part'>
      <p className='Registration-Misc'>On The Spot</p>
      <p>3:00pm - 6:00pm</p>
    </div>
</div>

</div>
    </>
  );
}

export default DummyCard0;
