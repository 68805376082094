import castleLayer from "../assets/images/CastleLayer.png";
import landLayer from "../assets/images/LandLayer.png";
import skyLayer from "../assets/images/SkyLayer.png";
import styles from './StartSection.module.css';
import { motion } from "framer-motion";

const StartSection = () => {
    return (
        <section className={styles['start-section']}>
            <motion.div
                className={`${styles['parallax-img-container']} ${styles['adjust-height']}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.7 }}
            >
                <img src={skyLayer} alt="Sky Layer" />
            </motion.div>
            <motion.div
                className={`${styles['parallax-img-container']} ${styles['adjust-height']}`}
                style={{ position: "absolute", top: 0 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
            >
                <img src={landLayer} alt="Land Layer" />
            </motion.div>
            <motion.div
                className={`${styles['parallax-img-container']} ${styles['adjust-height']}`}
                style={{ position: "absolute", top: 0 }}
                initial={{ opacity: 1 }}
                animate={{ opacity: 0 }}
                transition={{
                    delay: 0.6,
                    duration: 1,
                }}
            >
                <img src={skyLayer} alt="Sky Layer" />
            </motion.div>
            <motion.div
                className={styles['parallax-img-container']}
                style={{ position: "absolute", top: 0 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                    delay: 1,
                    duration: 0.7,
                }}
            >
                <img src={castleLayer} alt="Castle Layer" />
            </motion.div>
            <motion.div
                className={styles['coming-soon']}
                initial={{
                    y: "100vh",
                    x: "-50%",
                    opacity: 0,
                }}
                animate={{
                    y: -30,
                    opacity: 1,
                }}
                transition={{
                    delay: 2,
                    duration: 0.5,
                    type: "spring",
                    stiffness: 100,
                }}
            >
                <h1 className={`glow ${styles['resonanz-title']}`}>ResonanZ</h1>
                <h1 className={`glow ${styles['dates']}`}>13th - 15th Oct</h1>
            </motion.div>
            <span class={styles['four-point-star']}></span>
        </section>
    );
};

export default StartSection;
