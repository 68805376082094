import React from "react";
import styled from "styled-components";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiOutlineLinkedin } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import styles from "./TeamMember.module.css";

const TeamMember = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.img_container}>
        <img
          className={styles.img}
          alt="team member"
          loading="lazy"
          src={props.img_url}
        />
      </div>
      <span className={styles.name}>{props.name}</span>
      <span className={styles.role}>{props.role}</span>
      <a
        href={`https://www.instagram.com/${props.insta}/`}
        className={styles.social_container}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.insta !== "" ? (
          <>
            <span className={styles.social_icon}>
              <AiOutlineInstagram />
            </span>
            <span className={styles.social_handle}>{`@${props.insta}`}</span>
          </>
        ) : (
          <></>
        )}
      </a>
    </div>
  );
};

export default TeamMember;
