import TeamMember from "./TeamMember";
import styled from "styled-components";
import { teamMembers } from "../teamMembersData";
import Navbar from "./Navbar";
import Map from "./Map";
// import Footer from "./Footer";
const Container = styled.div`
  padding: 40px 40px;
  position: relative;
  @media only screen and (max-width: 600px) {
    padding: 40px 30px;
  }
`;

const BigTitle = styled.p`
  margin-top: 0.25em;
  font-size: 3em;
  font-weight: 600;
  color: #fcc200;
  font-family: "Klarissa";
  text-align: center;
  padding-top: 100px;
  @media only screen and (max-width: 940px) {
    padding-top: 60px;
  }
  @media only screen and (max-width: 550px) {
    padding-top: 40px;
  }
`;

const TeamMembersContainer = styled.p`
  display: flex;
  flex-wrap: wrap;
`;

const TeamSection = () => {
  return (
    <>
      <Navbar />
      <Container>
        <BigTitle>Our Team</BigTitle>
        <TeamMembersContainer>
          {teamMembers.map((data) => {
            return (
              <TeamMember
                key={data?.id}
                name={data?.name}
                role={data?.role}
                insta={data?.insta}
                img_url={data?.img_url}
              />
            );
          })}
        </TeamMembersContainer>
      </Container>
      {/* <Footer /> */}
    </>
  );
};

export default TeamSection;
