import React, { useState } from "react";
import resonanzteam from '../soclogos/resonanzteam.jpeg'
import clitchlogo from '../soclogos/clitchlogo.png'
import canvaslogo from '../soclogos/canvaslogo.png'
import junoon from '../soclogos/junoon.png'
import enactuslogo from '../soclogos/enactuslogo.png'
import miragelogo from '../soclogos/miragelogo.jpg'
import creslogo from '../soclogos/creslogo.png'
import asmelogo from '../soclogos/asmelogo.png'
import nakshatra from '../soclogos/nakshatra.png'
import shakesjeer from '../soclogos/shakesjeer.png'
import bhrlogo from '../soclogos/bhrlogo.png'
import rotaractlogo from '../soclogos/rotaractlogo.png'
import tatsamlogo from '../soclogos/tatsamlogo.jpg'
import kalpanalogo from '../soclogos/kalpanalogo.jpg'

import laughhoes from '../images/laughhoes.png'
import ballnight from '../images/ballnight.jpeg'
import resogottalent from '../images/resogottalent.jpeg'
import footloose from '../images/footloose.png'
import jamstream from '../images/jamstream.jpeg'
import bollyframes from '../images/bollyframes.png'
import outofthelamp from '../images/outofthelamp.png'
import planpitching from '../images/planpitching.png'
import campusbingo from '../images/campusbingo.png'
import smashriot from '../images/smashriot.png'
import cosmicquest from '../images/cosmicquest.png'
import modellingchallenge from '../images/modellingchallenge.png'
import guessthemovie from '../images/guessthemovie.png'
import mayajaal from '../images/mayajaal.png'

import "./DummyCard2.css";

function DummyCard3() {

    return (
        <>
            <div className='Card-Container'>

                <div className='Card'>
                    <div className='Description'>
                        <img src={resonanzteam} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>The Resonanz Team</h3>
                            <p>6th Block Terrace</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={ballnight} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/d/e/1FAIpQLSczRjUYatCQlAJiMiQVxZGRIIGvBqadPVAChQZMn7JNW_y0OA/viewform'><button className='Registration-Link'>Register Here!</button></a>
                        <p>5:00pm - 7:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={clitchlogo} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Clitch</h3>
                            <p>Admin</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={resogottalent} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://forms.gle/mmcTM1AdvyY7B8Gs5'><button className='Registration-Link'>Register Here!</button></a>
                        <p>1:00pm - 4:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={miragelogo} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Mirage-Capella</h3>
                            <p>Main Stage</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={footloose} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <p className='Registration-Misc'>Internal Reg.</p>
                        <p>2:00pm - 5:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img className='large-logos' src={shakesjeer} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Shakesjeers</h3>
                            <p>Main Stage</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={laughhoes} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1kDfKewDofnNF9hbQ04lbSgsHcw_LfvaWvmaD_LArRoA/edit?usp=sharing_eil_se_dm&ts=63370b28'><button className='Registration-Link'>Register Here!</button></a>
                        <p>11:30am - 2:30pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={creslogo} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Crescendo</h3>
                            <p>Main Audi/Apj 11</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={jamstream} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1148GczlXEnbcR5Nlbg7zc3aqYyglgDzo8zz8v5-4Npc/edit?usp=sharing_eip_se_dm&ts=6336a702'><button className='Registration-Link'>Register Here!</button></a>
                        <p>2:00pm - 6:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img className='white-logos' src={junoon} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>JUNOON</h3>
                            <p>Nescii Lawns</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={bollyframes} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1TseVtCE9XY6Yj_PuRtVD0O7GrD1yMwZOXTW2v1A3Jxs/edit?usp=sharing_eil_se_dm&ts=63371349'><button className='Registration-Link'>Register Here!</button></a>
                        <p>3:00pm - 5:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={canvaslogo} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>CANVAS</h3>
                            <p>Amul Ground</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={outofthelamp} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href=''><button className='Registration-Link'>Register Here!</button></a>
                        <p>12:00pm - 1:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img className='white-logos' src={enactuslogo} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Enactus</h3>
                            <p>Smart Block 4</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={planpitching} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/4/d/1xk3ul-NCkahCG3vim59cvZ4EMp3PNE0ihcAQax7Y8dM/edit?usp=sharing_eil_se_dm&ts=63373f77'><button className='Registration-Link'>Register Here!</button></a>
                        <p>11:00am - 3:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={rotaractlogo} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Rotaract</h3>
                            <p>Smart Block 2</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={guessthemovie} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/18gI3NpNVPUZqIxPSF18Hcj8IvjRe2pNqd53o1kW5rJ8/edit?usp=sharing_eil_se_dm&ts=6337189c'><button className='Registration-Link'>Register Here!</button></a>
                        <p>11:00am - 2:30pm</p>
                    </div>
                </div>
                
                <div className='Card'>
                    <div className='Description'>
                        <img src={bhrlogo} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>BHR</h3>
                            <p>Amul Ground</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={smashriot} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/11wiJJO7fljay0yMwF1Bj-i7iKwo3y017Wbn-wJsa-u4/edit?usp=sharing_eip_se_dm&ts=6336882f'><button className='Registration-Link'>Register Here!</button></a>
                        <p>2:00pm - 4:30pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img className='white-logos' src={kalpanalogo} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Team Kalpana</h3>
                            <p>Academic Block Lawns</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={campusbingo} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1Z6DShWHmwNKuYngswIersYMHNtJTDRJYIGnvF6zC-VY/edit?usp=sharing_eil_se_dm&ts=6335bd71'><button className='Registration-Link'>Register Here!</button></a>
                        <p>12:00pm - 4:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={nakshatra} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Nakshatra</h3>
                            <p>Nescii Lawns</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={cosmicquest} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/10z4X2JCfeg1kwkfh27UN2jQzAOuUtZQ3HehR5KKjIak/edit?usp=sharing_eil_se_dm&ts=6336e005'><button className='Registration-Link'>Register Here!</button></a>
                        <p>11:00am - 3:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={tatsamlogo} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>TATSAM</h3>
                            <p>Nescii Parking</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={mayajaal} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1usDIAfHc-rlFpw4RGoPk9HyBCZ1ZFNLxdSrJz5SqeeM/edit?usp=sharing_eil_se_dm&ts=633a8003'><button className='Registration-Link'>Register Here!</button></a>
                        <p>1:00pm - 5:00pm</p>
                    </div>
                </div>


                <div className='Card'>
                    <div className='Description'>
                        <img src={asmelogo} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>ASME</h3>
                            <p>Smart Block 2</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={modellingchallenge} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1pejdLgkfR9g1xQ1rg2rIAxj_20vYB_DhhSsZrByFKQg/edit?usp=sharing_eip_se_dm&ts=6336b874'><button className='Registration-Link'>Register Here!</button></a>
                        <p>3:00pm - 6:00pm</p>
                    </div>
                </div>

            </div>
        </>
    );
}

export default DummyCard3;
