import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl";
import styles from "./Map.module.css";
import 'mapbox-gl/dist/mapbox-gl.css';
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
const Map = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(77.038);
  const [lat, setLat] = useState(28.61);
  const [zoom, setZoom] = useState(15);
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
    });
  });
  return (
    <div className={styles['container']}>
      <div ref={mapContainer} className={styles['map-container']} />
    </div>
  );
};

export default Map;
