import styles from './BranchItem.module.css';

const BranchItem = (props) => {
    return (
        <div className={styles['branch-item']}>
            <h3 className={styles['name']}>{props.name}</h3>
            <h3 className={styles['score']}>{props.score}</h3>
        </div>
    )
}

export default BranchItem;