import React, {useEffect, useState} from "react";
import { useWindowSize} from 'react-use';
import { useLocation } from "react-router-dom";
import logo from '../assets/images/about.png'
import {motion, AnimatePresence} from "framer-motion";
import styles from './NavbarMobile.module.css';
import "./Navbar.css";
function Navbar() {

  const location = useLocation();

  const [expand, setExpand] = useState(true);
  const [open, setOpen] = useState(false);
  const { width } = useWindowSize();
  const NAVBAR_COLLAPSE_SIZE = 1000;
  const SCROLL_SOLID = location.pathname === '/home'? window.innerHeight: 0;

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > SCROLL_SOLID;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck)
      }
    });
  }, [scroll, SCROLL_SOLID]);

  useEffect(() => {
    setExpand(width > NAVBAR_COLLAPSE_SIZE);

    return () => {
      setExpand(true);
    };
  }, [width]);

  return (
    <>
      {expand && (<motion.div className={`navbar ${scroll? 'solid__nav': ''}`}
        initial={{ opacity: 0, y: '-100vh' }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.7 }}
      >
        <div className="navbar__left">
          <a href='/'><img
            src={logo}
            alt="logo"
            className="navbar__logo"
          /></a>
        </div>
        <div className="navbar__right">
            <div className="nav_ele">
              <a href="/home#about" className="nav_ele_href">About</a>
            </div>
            <div className="nav_ele">
              <a href="/home#sponsor" className="nav_ele_href">Sponsors</a>
            </div>
            <div className="nav_ele">
              <a href="/events" className="nav_ele_href">Events</a>
            </div>
            <div className="nav_ele">
              <a href="/team" className="nav_ele_href">Team</a>
            </div>
            <div className="nav_ele">
              <a href="/bob" className="nav_ele_href">Battle of Branches</a>
            </div>
          </div>
      </motion.div>)}
      {!expand && (
        <motion.div className={`navbar__mobile ${scroll? 'solid__nav': ''}`}
        initial={{ opacity: 0, y: '-100vh' }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.7 }}
        >
        <div className="navbar__top">
          <a href='/'><img
            src={logo}
            alt="logo"
            /></a>
        </div>
        <div className="hamburger">
          <AnimatePresence>
          {!open && <i class="fa fa-bars" onClick={() => setOpen(true)}></i>}
          {open && (
              <motion.div className={styles['menu']}
                initial={{ opacity: 0, y: '-100vh' }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.7 }}
                exit={{ opacity: 0, y: '-100vh' }}
                key='mobile-menu'
              >
                <i class="fa fa-times" onClick={() => setOpen(false)}></i>
                <div className="nav_ele">
                  <a href="/home#about" onClick={() => setOpen(false)} className="nav_ele_href"><i class="fa-solid fa-xmark"></i>About</a>
                </div>
                <div className="nav_ele">
                  <a href="/home#sponsor" onClick={() => setOpen(false)} className="nav_ele_href">Sponsors</a>
                </div>
                <div className="nav_ele">
                  <a href="/events" className="nav_ele_href">Events</a>
                </div>
                <div className="nav_ele">
                  <a href="/team" className="nav_ele_href">Team</a>
                </div>
                <div className="nav_ele">
              <a href="/bob" className="nav_ele_href">Battle of Branches</a>
            </div>
              </motion.div>
          )}
          </AnimatePresence>
          </div>
          </motion.div>
      )}
    </>
  );
}

export default Navbar;