import React, { useState } from "react";

import ashwamedh from '../soclogos/ashwamedh.png'
import shakesjeer from '../soclogos/shakesjeer.png'
import debsoclogo from '../soclogos/debsoclogo.jpg'
import creslogo from '../soclogos/creslogo.png'
import intaglios from '../soclogos/intaglios.PNG'

import canvaslogo from '../soclogos/canvaslogo.png'
import subhasha from '../soclogos/subhasha.jpg'
import tdrlogo from '../soclogos/tdrlogo.jpg'
import nakshatra from '../soclogos/nakshatra.png'
import areslogo from '../soclogos/areslogo.png'
import cdflogo from '../soclogos/cdflogo.png'
import clitchlogo from '../soclogos/clitchlogo.png'
import venatus from '../soclogos/venatus.png'
import axiomlogo from '../soclogos/axiomlogo.png'
import quizclublogo from '../soclogos/quizclublogo.png'


import mrmsreso from '../images/mrmsreso.jpeg'
import spoton from '../images/spoton.jpeg'
import nautanki from '../images/nautanki.jpeg'
import duelofwords from '../images/duelofwords.png'
import kalesh from '../images/kalesh.png'

import tasteofmagic from '../images/tasteofmagic.png'
import stepsofsultan from '../images/stepsofsultan.png'
import chooseyourchronicle from '../images/chooseyourchronicle.png'
import satisfythegenie from '../images/satisfythegenie.png'
import twotruthsonelie from '../images/twotruthsonelie.png'
import kyahukummereaaka from '../images/kyahukummereaaka.png'
import triathlon from '../images/triathlon.png'
import aladdinsrobo from '../images/aladdinsrobo.png'
import fashionparade from '../images/fashionparade.jpeg'
import dynamojuego from '../images/dynamojuego.jpeg'
import popquiz from '../images/popquiz.jpeg'

import "./DummyCard2.css";

function DummyCard2() {

    return (
        <>

            <div className='Card-Container'>

                
                <div className='Card'>
                    <div className='Description'>
                        <img src={clitchlogo} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Clitch</h3>
                            <p>Main Stage</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={fashionparade} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/d/e/1FAIpQLSekuQlI7lLkiqD66jV90Holr3p1fcGVaxNHqe6hFp_W27YjIA/viewform'><button className='Registration-Link'>Register Here!</button></a>
                        <p>2:45pm - 5:15pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={intaglios} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Intaglios</h3>
                            <p>Main Stage</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={mrmsreso} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1U6t75TJ35cLRqkFRdNnkPJyVXarwnPZ_dQeQqLyTs9w/edit?usp=sharing_eil_se_dm&ts=6335b0a0'><button className='Registration-Link'>Register Here!</button></a>
                        <p>1:00pm - 5:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={venatus} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Venatus</h3> 
                            <p>Smart Block 1 & 3</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={dynamojuego} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://forms.gle/2K9VAzMbZRGWAX8h9'><button className='Registration-Link'>Register Here!</button></a>
                        <p>11:00am - 6:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img className='large-logos' src={shakesjeer} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Shakesjeers</h3>
                            <p>Admin</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={spoton} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1avta-l8lXFGWGNap8R4UoydDw9cliu-QbwmVPVZfbcY/edit?usp=sharing_eip_se_dm&ts=6336c4cb'><button className='Registration-Link'>Register Here!</button></a>
                        <p>2:30pm - 5:30pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={ashwamedh} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Ashwamedh</h3>
                            <p>Main Audi/Apj 11</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={nautanki} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/d/e/1FAIpQLSeHhhkscrgh9VucP6Q1vlHimTvsMULAgQYa4Sf0OrjDdvIANQ/viewform'><button className='Registration-Link'>Register Here!</button></a>
                        <p>11:00am - 6:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={debsoclogo} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>DebSoc</h3>
                            <p>Smart Block 2</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={duelofwords} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1v11-pJET8aUIVAJGyVZlLESZVv5UDXmOdyawBFRDX_s/edit?usp=sharing_eil_se_dm&ts=6337145f'><button className='Registration-Link'>Register Here!</button></a>
                        <p>12:00pm - 2:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={creslogo} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Crescendo</h3>
                            <p>Admin</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={kalesh} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1CPK2KYSdypC9oBiXOpgPlZRSiVpGzKi5PgRdvnlcDXA/edit?usp=sharing_eil_se_dm&ts=633722f6'><button className='Registration-Link'>Register Here!</button></a>
                        <p>11:00am - 2:00pm</p>
                    </div>
                </div>
           
                   

                <div className='Card'>
                    <div className='Description'>
                        <img alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>THC</h3>
                            <p>Smart Block 1</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={tasteofmagic} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1AwDqDm6E8yLUxQqUaqM7M4T78lJSCLEydysyIBshCTY/edit?usp=sharing_eil_se_dm&ts=633666ce'><button className='Registration-Link'>Register Here!</button></a>
                        <p>12:00pm - 5:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={canvaslogo} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>CANVAS</h3>
                            <p>Amul Ground</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={stepsofsultan} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1ndi_0JUVpCL2xQsZsq8OnAH0w1fVuhS2YkyHnZD5F_M/edit?usp=sharing_eil_se_dm&ts=6335c1ef'><button className='Registration-Link'>Register Here!</button></a>
                        <p>12:00pm - 4:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img className='large-logos 'src={subhasha} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Subhasha</h3>
                            <p>Nescii Lawn</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={chooseyourchronicle} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1t_EJNPEBOjPJR4LsTQ8fmpth9kWs6JVDQa-VxE6msyc/edit?usp=sharing_eil_se_dm&ts=63370960'><button className='Registration-Link'>Register Here!</button></a>
                        <p>12:00pm - 4:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={cdflogo} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>CDF</h3>
                            <p>Nescii Lawn</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={satisfythegenie} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                    <p className='Registration-Misc'>On The Spot</p>
                        <p>12:00pm - 4:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={quizclublogo} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Quiz Club</h3>
                            <p>Smart Block 2</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={popquiz} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                    <a target="_blank" href='https://docs.google.com/forms/u/7/d/1MwnjnHxGi3FRZ8N10lDMqln4Ni9-R1XgdLmV3h-oCOY/edit?usp=sharing_eil_se_dm&ts=6336d518'><button className='Registration-Link'>Register Here!</button></a>
                        <p>2:00pm - 4:30pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={axiomlogo} className='white-logos' alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Axiom</h3>
                            <p>Nescii Parking</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={twotruthsonelie} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1fY78460w3f8tMwpl-BSIoEBWsx_uXGEtuEwsFCs1y1w/edit?usp=sharing_eip_se_dm&ts=63367d40'><button className='Registration-Link'>Register Here!</button></a>
                        <p>11:30am - 3:30pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={tdrlogo} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>TDR</h3>
                            <p>Academic Block Lawns</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={kyahukummereaaka} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/16JQXbKs9oq9-XXEAhlhN4THgCFFx69b3Src4BOhrL1Y/edit?usp=sharing_eip_se_dm&ts=63358e1e'><button className='Registration-Link'>Register Here!</button></a>
                        <p>11:00am - 6:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={nakshatra} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Nakshatra</h3>
                            <p>Smart Block 4</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={triathlon} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1_ZDw6jHN0e0gnkUTD5bwofHX1_INGt0S6kwOlW2bzdI/edit?usp=sharing_eil_se_dm&ts=6336f91a'><button className='Registration-Link'>Register Here!</button></a>
                        <p>11:00am - 2:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={areslogo} className='white-logos' alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>ARES Robotics</h3>
                            <p>Block 6th Terrace</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={aladdinsrobo} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1gtUt3dKOvq2UWAz6y9o3ZlLTNcGTKTw3KAurMSYKR6E/edit?usp=sharing_eil_se_dm&ts=63370a23'><button className='Registration-Link'>Register Here!</button></a>
                        <p>2:00pm - 6:00pm</p>
                    </div>
                </div>


            </div>
        </>
    );
}

export default DummyCard2;
