import React, { useState } from "react";
import styles from "./Footer.module.css";
import { BsTelephone } from "react-icons/bs";
import { FiMapPin } from "react-icons/fi";
import Map from "./Map";
const Footer = () => {
  const lat = 28.61;
  const lng = 77.038;

  return (
    <div className={styles["footer_container"]}>
      <div className={styles["footer"]}>
        <span class={styles["four-point-star"]}></span>
        <div className="info_container">
          <div className={styles["contacts_container"]}>
            <div className={styles["phone_icon"]}>{<BsTelephone />}</div>
            <div className={styles["contacts_items"]}>
              <div className={styles["contact_item"]}>
                <span className={styles["contact_heading"]}>
                  +91-7891661335
                </span>
                <span className={styles["contact_subheading"]}>
                  Pranshu, Chief Covener
                </span>
              </div>
              <div className={styles["contact_item"]}>
                <span className={styles["contact_heading"]}>
                  +91-9818129235
                </span>
                <span className={styles["contact_subheading"]}>
                  Arjun, Sponsorship Head
                </span>
              </div>
              <div className={styles["contact_item"]}>
                <span className={styles["contact_heading"]}>
                  +91-7668201298
                </span>
                <span className={styles["contact_subheading"]}>
                  Parth, Sponsorship Head
                </span>
              </div>
            </div>
          </div>
          {/* //address */}
          <div className={styles["address_container"]}>
            <div className={styles["map_icon"]}>{<FiMapPin />}</div>
            <div className={styles["address_item"]}>
              <span className={styles["address_heading"]}>
                Netaji Subhas University of Technology
              </span>
              <span className={styles["address_subheading"]}>
                Dwarka Sector - 3, Delhi, 110078
              </span>
            </div>
          </div>
        </div>
        <Map />
      </div>
    </div>
  );
};

export default Footer;
