export const teamMembers = [
  {
    id: 5,
    name: "Pranshu Birla",
    insta: "",
    img_url:
      "https://res.cloudinary.com/hackon-technophiles/image/upload/v1664967330/Pranshu_mq7kv0.jpg",
    role: "Chief Convener",
  },
  {
    id: 6,
    name: "Hardeek Sharma",
    insta: "hardeeeeeeek",
    img_url:
      "https://res.cloudinary.com/ddggwxea1/image/upload/v1664798079/Hardeek_js8032.jpg",
    role: "Co - Convener",
  },
  {
    id: 7,
    name: "Mehak Gupta",
    insta: "mehak_gupta20",
    img_url:
      "https://res.cloudinary.com/dksp0pads/image/upload/v1664988853/reso/_Mehak_gupta_ezvpj5.jpg",
    role: "Co - Convener",
  },
  {
    id: 8,
    name: "Kaustav Mukherjee",
    insta: "kauxxtav",
    img_url:
      "https://res.cloudinary.com/dksp0pads/image/upload/v1664988918/reso/Kaustav_p6dbra.jpg",
    role: "General Secretary",
  },
  {
    id: 9,
    name: "Shivansh Aggrawal",
    insta: "shivansh_agarwal_07",
    img_url:
      "https://res.cloudinary.com/hackon-technophiles/image/upload/v1664967331/shivansh_uvpkm5.jpg",
    role: "Joint Gen. Secretary",
  },
  {
    id: 10,
    name: "Ritik Yadav",
    insta: "ritikyadav_675",
    img_url:
      "https://res.cloudinary.com/ddggwxea1/image/upload/v1664798078/Ritik_Yadav_z1rfew.jpg",
    role: "Head‚ Event Management",
  },
  {
    id: 11,
    name: "Trish Arora",
    insta: "trish.arora",
    img_url:
      "https://res.cloudinary.com/hackon-technophiles/image/upload/v1664967331/Trish_vbcwxg.jpg",
    role: "Head, Event Management",
  },
  {
    id: 12,
    name: "Mitansh Khuranna",
    insta: "mitansh.khurana10",
    img_url:
      "https://res.cloudinary.com/dksp0pads/image/upload/v1665087796/reso/Mitansh_c3tgim.jpg",
    role: "Mentor, Sponsorship",
  },
  {
    id: 14,
    name: "Arjun Narula",
    insta: "arjunnarula08",
    img_url:
      "https://res.cloudinary.com/dksp0pads/image/upload/v1664989184/reso/Arjun_bshfz3.jpg",
    role: "Head, Sponsorship",
  },
  {
    id: 15,
    name: "Parth Agarwal",
    insta: "parth.vka",
    img_url:
      "https://res.cloudinary.com/dksp0pads/image/upload/v1665087060/reso/Parth_cxsr2w.jpg",
    role: "Head, Sponsorship",
  },
  {
    id: 16,
    name: "John",
    insta: "johnpkuriyen",
    img_url:
      "https://res.cloudinary.com/dksp0pads/image/upload/v1664989842/reso/John_w3aqri.jpg",
    role: "Head, Sponsorship",
  },
  {
    id: 17,
    name: "Devashish",
    insta: "devashish___",
    img_url:
      "https://res.cloudinary.com/dksp0pads/image/upload/v1664989839/reso/Devashish_vashisth_fjg8xw.jpg",
    role: "Head, Sponsorship",
  },
  {
    id: 17,
    name: "Priyanshi Chauhan",
    insta: "priishuuuuuu",
    img_url:
      "https://res.cloudinary.com/ddggwxea1/image/upload/v1664798077/Priyanshi_chauhan_hnextx.jpg",
    role: "Head, Public Relations",
  },
  {
    id: 18,
    name: "Daksh Panchal",
    insta: "_dakshpanchal",
    img_url:
      "https://res.cloudinary.com/ddggwxea1/image/upload/v1664798080/DAKSH_PANCHAL_pqviaz.jpg",
    role: "Head, Public Relations ",
  },
  {
    id: 19,
    name: "Baani Yadav",
    insta: "b.aa.ni",
    img_url:
      "https://res.cloudinary.com/ddggwxea1/image/upload/v1664798081/Baani_Yadav_e5izty.jpg",
    role: "Head, Public Relations",
  },
  {
    id: 20,
    name: "Vaibhav Sharma",
    insta: "vaibhavvvsharma",
    img_url:
      "https://res.cloudinary.com/dksp0pads/image/upload/v1664989174/reso/Vaibhav_Sharma_sx3dxi.jpg",
    role: "Head, Logistics",
  },
  {
    id: 21,
    name: "Yash Chauhan",
    insta: "yashchauhan_04",
    img_url:
      "https://res.cloudinary.com/hackon-technophiles/image/upload/v1664967334/yash_idy2im.jpg",
    role: "Head, Logistics",
  },
  {
    id: 22,
    name: "Chirag Jindal",
    insta: "chiragjindal9811",
    img_url:
      "https://res.cloudinary.com/hackon-technophiles/image/upload/v1664967332/Chirag_Jindal_xojdpg.jpg",
    role: "Head, Logistics",
  },
  {
    id: 28,
    name: "Raman Chaudhary",
    insta: "ramanchaudharyy",
    img_url:
      "https://res.cloudinary.com/dksp0pads/image/upload/v1664989272/reso/Raman_Chaudhary_rf9c7v.jpg",
    role: "Head, Security",
  },
  {
    id: 29,
    name: "Ankit Yadav",
    insta: "",
    img_url:
      "https://res.cloudinary.com/dksp0pads/image/upload/v1664989841/reso/Ankit_Yadav_cz7kuw.jpg",
    role: "Head, Security",
  },
  {
    id: 30,
    name: "Japneet Singh",
    insta: "",
    img_url:
      "https://res.cloudinary.com/dksp0pads/image/upload/v1664990301/reso/Japneet_wjncct.png",
    role: "Head, Production",
  },
  {
    id: 23,
    name: "Aditya Singh",
    insta: "ugh_ditya",
    img_url:
      "https://res.cloudinary.com/dksp0pads/image/upload/v1664994014/reso/Aditya_Singh_wjr9or.png",
    role: "Mentor, Design",
  },
  {
    id: 23,
    name: "Nitash Biswas",
    insta: "nitashbiswas",
    img_url:
      "https://res.cloudinary.com/dksp0pads/image/upload/v1664989447/reso/Nitash_Biswas_1_moijqa.jpg",
    role: "Head, Design",
  },
  {
    id: 24,
    name: "Jaskaran Singh Chahal",
    insta: "",
    img_url:
      "https://res.cloudinary.com/hackon-technophiles/image/upload/v1664967330/jaskaran_geu58j.jpg",
    role: "Head, Design",
  },
  {
    id: 25,
    name: "Lakshay Gulati",
    insta: "lakshayx9",
    img_url:
      "https://res.cloudinary.com/ddggwxea1/image/upload/v1664798081/Lakshay_Gulati_jumuxy.png",
    role: "Head, Design",
  },
  {
    id: 26,
    name: "Tanishq Singh",
    insta: "tanishq_singh19",
    img_url:
      "https://res.cloudinary.com/dksp0pads/image/upload/v1664989590/reso/Tanishq_singh_1_d9cwnb.jpg",
    role: "Head, Fine Arts",
  },
  {
    id: 27,
    name: "Shaurya Aryan",
    insta: "thegeekyfreak",
    img_url:
      "https://res.cloudinary.com/ddggwxea1/image/upload/v1664798081/Shaurya_Aryan_qrkpn3.jpg",
    role: "Head, Fine Arts",
  },
  {
    id: 31,
    name: "Sankalp Roy",
    insta: "_sankalproy",
    img_url:
      "https://res.cloudinary.com/dksp0pads/image/upload/v1664993164/reso/Sankalp_s3rczk.jpg",
    role: "Director Of Photography",
  },
  {
    id: 1,
    name: "Kartik Bagri",
    insta: "kartik_bagri",
    img_url:
      "https://res.cloudinary.com/ddggwxea1/image/upload/v1665234986/Kartik_Bagri_fl4e0f_daazjt.jpg",
    role: "Web Developer",
  },
  {
    id: 2,
    name: "Ajay Sharma",
    insta: "__aj2000__",
    img_url:
      "https://res.cloudinary.com/ddggwxea1/image/upload/v1664798082/ajay_sharma_wf88ww.jpg",
    role: "Web Developer",
  },
  {
    id: 3,
    name: "Pratyush Gupta",
    insta: "pratyush.gupta_",
    img_url:
      "https://res.cloudinary.com/ddggwxea1/image/upload/v1664798080/Pratyush_Gupta_2_vicjah.jpg",
    role: "Web Developer",
  },
  {
    id: 4,
    name: "Pranav Chauhan",
    insta: "thepranav.chauhan",
    img_url:
      "https://res.cloudinary.com/ddggwxea1/image/upload/v1664798078/Pranav_Chauhan_otfbpw.jpg",
    role: "Web Developer",
  },

  // {
  //     id: 1,
  //     name: "",
  //     insta: "",
  //     img_url: "",
  //     role: ""
  // }
]; //https://res.cloudinary.com/hackon-technophiles/image/upload/v1664964267/Avatar-Profile-PNG-Photos_nnxaql.png
