import aboutImg from "../assets/images/F3B26E3F-80C3-4487-A185-96384F1CE43C.jpeg";

const NewSponsors = () => {
  return (
    <section
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "60px",
      }}
    >
      <a
        style={{ textDecoration: "none" }}
        href="https://www.instagram.com/p/CjnIa_9p8vO/"
      >
        <div style={{ width: "400px", height: "400px" }}>
          <img src={aboutImg} style={{ width: "100%", height: "100%" }} />
        </div>
      </a>
    </section>
  );
};

export default NewSponsors;
