import "./sponsers.css";
import { useEffect, useState } from "react";
import TagCloud from "TagCloud";
import img_path1 from "./sponsersNewSvg/bisleri.svg";
import img_path2 from "./sponsersNewSvg/bluehost.svg";
import img_path3 from "./sponsersNewSvg/club_mahindra.svg";
import img_path4 from "./sponsersNewSvg/comedy_central.svg";
import img_path5 from "./sponsersNewSvg/coolwinks.svg";
import img_path6 from "./sponsersNewSvg/du_beats.svg";
import img_path7 from "./sponsersNewSvg/fever_fm.svg";
import img_path8 from "./sponsersNewSvg/gpt.svg";
import img_path9 from "./sponsersNewSvg/HP.svg";
import img_path10 from "./sponsersNewSvg/max.svg";
import img_path11 from "./sponsersNewSvg/moserbaer.svg";
import img_path12 from "./sponsersNewSvg/ndpl.svg";
import img_path13 from "./sponsersNewSvg/pnb.svg";
import img_path14 from "./sponsersNewSvg/red_fm.svg";
import img_path15 from "./sponsersNewSvg/saavn.svg";
import img_path16 from "./sponsersNewSvg/servo.svg";
import img_path17 from "./sponsersNewSvg/the_college_fever.svg";
import img_path18 from "./sponsersNewSvg/the_hindu.svg";
import img_path19 from "./sponsersNewSvg/tinder.svg";
import img_path20 from "./sponsersNewSvg/UBI.svg";





function PastSponsers() {
  const [screenWidth, SetScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      SetScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  });


  useEffect(() => {
    let Texts = [
      "CocaCola",
      "ebay",
      "OLA",
      "MTV",
      "AIR INDIA",
      "ASUS",
      "NOKIA",
      "paytm",
      "HONDA",
      "Bharat Petroleum",
      "!dea",
      "spykar",
      "Zoomcar",
      "MOTHER DAIRY",
      "sparx",
      "Hero",
      "OYO",
      "HITACHI"]
      
      let width = screenWidth/5;

      if (screenWidth < 1020) {
        width = screenWidth/3;
      }
    console.log('hi');
    var tagCloud = TagCloud(".Sphere", Texts, {
      radius: width,
      maxSpeed: "fast",
      initSpeed: "fast",
      direction: 135,
      keep: true,
    });
  }, []);

  return (
    <div className="past-sponser" id="sponsor">
      <div class='sponser-upper'>
        <div class='sponser-left'>
          <div class='sponser-heading'>Past Sponsors</div>
          <div class='sponser-para'>
          Resonanz has always been sponsored by the best of multinational companies. The top sponsors in recent years have been not just giants like Cocacola, Indian Oil, Air India, ASUS, BHEL, The Hindu, ONGC, etc. but also the startups defining the new age like Tinder, Paytm, Oyo, ZoomCar, Ola, inshorts, and others. Even if the list of sponsors is endless just like the energy the whole fest commemorates, each of our sponsors has always been able to get in the hearts of thousands of people that come to enjoy the magical festival.
          </div>
        </div>
        <div class='sponser-right'>
          <span class='Sphere'></span>
        </div>
      </div>

      <div class='sponser-marquee'>
        <div class='marquee'>
          <ul class='marquee-content right'>
          <li>
              <img src={img_path1} />
            </li>
            <li>
              <img src={img_path2} />
            </li>
            <li>
              <img src={img_path3} />
            </li>
            <li>
              <img src={img_path4} />
            </li>
            <li>
              <img src={img_path5} />
            </li>
            <li>
              <img src={img_path6} />
            </li>
            <li>
              <img src={img_path7} />
            </li>
            <li>
              <img src={img_path8} />
            </li>
            <li>
              <img src={img_path9} />
            </li>
            <li>
              <img src={img_path10} />
            </li>
          </ul>
        </div>

        <div class='marquee'>
          <ul class='marquee-content left'>
            <li>
              <img src={img_path11} />
            </li>
            <li>
              <img src={img_path12} />
            </li>
            <li>
              <img src={img_path13} />
            </li>
            <li>
              <img src={img_path14} />
            </li>
            <li>
              <img src={img_path15} />
            </li>
            <li>
              <img src={img_path16} />
            </li>
            <li>
              <img src={img_path17} />
            </li>
            <li>
              <img src={img_path18} />
            </li>
            <li>
              <img src={img_path19} />
            </li>
            <li>
              <img src={img_path20} />
            </li>
            <li>
              <img src={img_path11} />
            </li>
            <li>
              <img src={img_path12} />
            </li>
            <li>
              <img src={img_path13} />
            </li>
            <li>
              <img src={img_path14} />
            </li>
            <li>
              <img src={img_path15} />
            </li>
           
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PastSponsers;