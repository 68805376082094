const day1 = [
    { name: 'B.Tech CSAI', score: 0 },
    { name: 'B.Tech COE', score: 0 },
    { name: 'B.Tech CSDS', score: 0 },
    { name: 'B.Tech CSDA', score: 0 },
    { name: 'B.Tech IT', score: 0 },
    { name: 'B.Tech IT (IoT)', score: 0 },
    { name: 'B.Tech ITNS', score: 0 },
    { name: 'B.Tech MAC', score: 0 },
    { name: 'B.Tech ECE', score: 0 },
    { name: 'B.Tech ECE (AI and ML)', score: 0 },
    { name: 'B.Tech ECE (IoT)', score: 0 },
    { name: 'B.Tech ICE', score: 0 },
    { name: 'B.Tech EE', score: 0 },
    { name: 'B.Tech Mechanical', score: 0 },
    { name: 'B.Tech BT', score: 0 },
    { name: 'B.Tech MPAE', score: 0 },
    { name: 'B.Tech MEEV', score: 0 },
    { name: 'B.Tech Geoinformatics', score: 0 },
    { name: 'B.Tech Civil', score: 0 },
    { name: 'B.Tech Biotechnology', score: 0 },
    { name: 'M. Tech CSE', score: 0 },
    { name: 'M. Tech AI', score: 0 },
    { name: 'M. Tech VLSI', score: 0 },
    { name: 'M. Tech EE', score: 0 },
    { name: 'M. Tech Bioinformatics', score: 0 },
    { name: 'M. Tech Mechanical', score: 0 },
    { name: 'M. Tech Environmental', score: 0 },
    { name: 'BBA', score: 0 },
    { name: 'MBA', score: 0 },
    { name: 'MSc Physics', score: 0 },
    { name: 'MSc Chemistry', score: 0 },
    { name: 'BF. Tech', score: 0 }
]

const day2 = [
    { name: 'B.Tech CSAI', score: 0 },
    { name: 'B.Tech COE', score: 0 },
    { name: 'B.Tech CSDS', score: 0 },
    { name: 'B.Tech CSDA', score: 0 },
    { name: 'B.Tech IT', score: 0 },
    { name: 'B.Tech IT (IoT)', score: 0 },
    { name: 'B.Tech ITNS', score: 0 },
    { name: 'B.Tech MAC', score: 0 },
    { name: 'B.Tech ECE', score: 0 },
    { name: 'B.Tech ECE (AI and ML)', score: 0 },
    { name: 'B.Tech ECE (IoT)', score: 0 },
    { name: 'B.Tech ICE', score: 0 },
    { name: 'B.Tech EE', score: 0 },
    { name: 'B.Tech Mechanical', score: 0 },
    { name: 'B.Tech BT', score: 0 },
    { name: 'B.Tech MPAE', score: 0 },
    { name: 'B.Tech MEEV', score: 0 },
    { name: 'B.Tech Geoinformatics', score: 0 },
    { name: 'B.Tech Civil', score: 0 },
    { name: 'B.Tech Biotechnology', score: 0 },
    { name: 'M. Tech CSE', score: 0 },
    { name: 'M. Tech AI', score: 0 },
    { name: 'M. Tech VLSI', score: 0 },
    { name: 'M. Tech EE', score: 0 },
    { name: 'M. Tech Bioinformatics', score: 0 },
    { name: 'M. Tech Mechanical', score: 0 },
    { name: 'M. Tech Environmental', score: 0 },
    { name: 'BBA', score: 0 },
    { name: 'MBA', score: 0 },
    { name: 'MSc Physics', score: 0 },
    { name: 'MSc Chemistry', score: 0 },
    { name: 'BF. Tech', score: 0 }
]

const day3 = [
    { name: 'B.Tech CSAI', score: 0 },
    { name: 'B.Tech COE', score: 0 },
    { name: 'B.Tech CSDS', score: 0 },
    { name: 'B.Tech CSDA', score: 0 },
    { name: 'B.Tech IT', score: 0 },
    { name: 'B.Tech IT (IoT)', score: 0 },
    { name: 'B.Tech ITNS', score: 0 },
    { name: 'B.Tech MAC', score: 0 },
    { name: 'B.Tech ECE', score: 0 },
    { name: 'B.Tech ECE (AI and ML)', score: 0 },
    { name: 'B.Tech ECE (IoT)', score: 0 },
    { name: 'B.Tech ICE', score: 0 },
    { name: 'B.Tech EE', score: 0 },
    { name: 'B.Tech Mechanical', score: 0 },
    { name: 'B.Tech BT', score: 0 },
    { name: 'B.Tech MPAE', score: 0 },
    { name: 'B.Tech MEEV', score: 0 },
    { name: 'B.Tech Geoinformatics', score: 0 },
    { name: 'B.Tech Civil', score: 0 },
    { name: 'B.Tech Biotechnology', score: 0 },
    { name: 'M. Tech CSE', score: 0 },
    { name: 'M. Tech AI', score: 0 },
    { name: 'M. Tech VLSI', score: 0 },
    { name: 'M. Tech EE', score: 0 },
    { name: 'M. Tech Bioinformatics', score: 0 },
    { name: 'M. Tech Mechanical', score: 0 },
    { name: 'M. Tech Environmental', score: 0 },
    { name: 'BBA', score: 0 },
    { name: 'MBA', score: 0 },
    { name: 'MSc Physics', score: 0 },
    { name: 'MSc Chemistry', score: 0 },
    { name: 'BF. Tech', score: 0 }
]

export { day1, day2, day3 };