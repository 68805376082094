import Navbar from "../Navbar";
import styles from './BattleOfBranches.module.css';
import BranchItem from "./BranchItem";
import { day1, day2, day3 } from "./scores";
import { motion } from 'framer-motion'
import { useState } from 'react';

const totalScoreCalculator = () => {

    const totalScoreMap = new Map();
    day1.forEach((branch) => {
        totalScoreMap.set(branch.name, branch.score);
    });
    day2.forEach((branch) => {
        totalScoreMap.set(branch.name, totalScoreMap.get(branch.name) + branch.score);
    });
    day3.forEach((branch) => {
        totalScoreMap.set(branch.name, totalScoreMap.get(branch.name) + branch.score);
    });
    const total = [];
    totalScoreMap.forEach((score, name) => {
        total.push({ name, score });
    });
    return total;
}

const BattleOfBranches = () => {
    
    const total = totalScoreCalculator();

    day1.sort((a, b) => b.score - a.score);
    day2.sort((a, b) => b.score - a.score);
    day3.sort((a, b) => b.score - a.score);
    total.sort((a, b) => b.score - a.score);

    const [selectedDay, setSelectedDay] = useState(day1);
    const [selectedDayName, setSelectedDayName] = useState('day1');

    return (
        <>
        <Navbar />
        <section className={styles['page']}>
            <h1 className={styles['title']}>Battle of Branches</h1>
            <h2 className={styles['subtitle']}>Leaderboards</h2>
            <div className={styles['container']}>
                <div className={styles['tabs-container']}>
                    <div className={`${styles['tab']} ${selectedDayName === 'day1' ? styles['selected'] : ''}`}
                        onClick={() => {
                            setSelectedDayName('day1');
                            setSelectedDay(day1)
                        }}
                    >
                        DAY 1
                    </div>
                    <div className={`${styles['tab']} ${selectedDayName === 'day2' ? styles['selected'] : ''}`}

                        onClick={() => {
                            setSelectedDayName('day2');
                            setSelectedDay(day2)
                        }}
                    >
                        DAY 2
                    </div>
                    <div className={`${styles['tab']} ${selectedDayName === 'day3' ? styles['selected'] : ''}`}
                        onClick={() => {
                            setSelectedDayName('day3');
                            setSelectedDay(day3)
                        }}
                    >
                        DAY 3
                    </div>
                    <div className={`${styles['tab']} ${selectedDayName === 'total' ? styles['selected'] : ''}`}
                        onClick={() => {
                            setSelectedDayName('total');
                            setSelectedDay(total)
                        }}
                    >
                        ScoreBoards
                    </div>
                </div>
                <motion.div className={styles['content-container']}
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                >
                    {selectedDay.map(branch => (
                        <BranchItem name={branch.name} score={branch.score} />
                    ))}
                </motion.div>
            </div>
        </section>
        </>
    )
}

export default BattleOfBranches;