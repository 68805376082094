import './App.css';
import IntroVideo from './Components/IntroVideo';
import StartSection from './Components/StartSection';
import TeamSection from './Components/TeamSection';
import About from './Components/About';
import ContactUs from './Components/ContactUs';
import PastSponsers from './Components/PastSponsers/sponser';
import { Routes, Route, Link, useLocation } from "react-router-dom";
import Event from './Cards/Event';
import Home from './Components/Home';
import BattleOfBranches from './Components/BattleOfBranches/BattleOfBranches';
import Footer from './Components/Footer';
const App = () => {
  const location = useLocation();
  return (
    <>
     <Routes location={location} key={location.pathname}>
        <Route path="/" element={<IntroVideo />} />
        <Route path="/team" element={<TeamSection />} />
        <Route path="/home" element={<Home />} />
        <Route path="/events" element={<Event />} />
        <Route path="/bob" element={<BattleOfBranches />} />
        <Route path="/footer" element={<Footer />} />
      </Routes>
      
    </>
   
  )
}

export default App;
