import loaderImg from '../assets/images/loader.gif';
import styles from './Loader.module.css';

const Loader = () => {
    return (
        <div className={styles['loader-backdrop']}>
            <img src={loaderImg} alt='Loading...'></img>
        </div>
    )
}

export default Loader;