import React, { useState } from "react";
import { BsWindowSidebar } from "react-icons/bs";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import DummyCard0 from "./Dummycard0";
import DummyCard1 from "./DummyCard1";
import DummyCard2 from "./DummyCard2";
import DummyCard3 from "./DummyCard3";

function Event() {
  const [value, setvalue] = useState(0);
  return (
    <><>
      <Navbar />
      <div>
        <div className="tabs_events">
          <div className="tab_ele" onClick={() => setvalue(0)}>
            Pre-Fest
          </div>
          <div className="tab_ele" onClick={() => setvalue(1)}>
            Day 1
          </div>
          <div className="tab_ele" onClick={() => setvalue(2)}>
            Day 2
          </div>
          <div className="tab_ele" onClick={() => setvalue(3)}>
            Day 3
          </div>
          <div className="tab_ele" onClick={() => {window.location.href = `https://docs.google.com/spreadsheets/d/1fzUKT2TS5w25m0mpcs-QmRLu8KZOJQNeltfStumZY-g/edit?usp=sharing` ;}}>
            Schedule
          </div>
          <div className="tab_ele" onClick={() => {window.location.href = `https://docs.google.com/spreadsheets/d/1Wn4Dh5OaGx9k7vcXXGFGACTArWCncu9yyAyO8mLXt9A/edit?usp=sharing` ;}}>
            Time Table
          </div>


        </div>
        {value === 0 && <DummyCard0 />}
        {value === 1 && <DummyCard1 />}
        {value === 2 && <DummyCard2 />}
        {value === 3 && <DummyCard3 />}

      </div>
    </><Footer /></>    
  );
}

export default Event;
