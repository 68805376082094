import { Carousel } from 'react-responsive-carousel';
import styles from './History.module.css';
import pic1 from '../assets/images/history/1.jpg'
import pic2 from '../assets/images/history/2.jpg'
import pic3 from '../assets/images/history/3.jpg'
import pic4 from '../assets/images/history/4.jpg'
import pic5 from '../assets/images/history/5.jpg'
import pic6 from '../assets/images/history/6.jpg'
import pic7 from '../assets/images/history/7.jpg'
import pic8 from '../assets/images/history/8.jpg'
import "react-responsive-carousel/lib/styles/carousel.min.css";

const History = () => {
    return (
        <section className={styles['section']}>
            <h1 className={styles['title']}>Glorious History</h1>
            <div className={styles['text-container']}>
                <p>The reverbs of the past carries us into the resonanz of tomorrow...</p>
            </div>
            <div className={styles['carousel-container']}>
                <Carousel autoPlay={true} interval={3000} stopOnHover={false} infiniteLoop={true} showThumbs={false} showArrows={false}>
                    <div className={styles['container']}>
                        <img src={pic1} alt='pic 1'/>
                    </div>
                    <div className={styles['container']}>
                        <img src={pic2} alt='pic 2'/>
                    </div>
                    <div className={styles['container']}>
                        <img src={pic3} alt='pic 3'/>
                    </div>
                    <div className={styles['container']}>
                        <img src={pic4} alt='pic 4'/>
                    </div>
                    <div className={styles['container']}>
                        <img src={pic5} alt='pic 5'/>
                    </div>
                    <div className={styles['container']}>
                        <img src={pic6} alt='pic 6'/>
                    </div>
                    <div className={styles['container']}>
                        <img src={pic7} alt='pic 7'/>
                    </div>
                    <div className={styles['container']}>
                        <img src={pic8} alt='pic 8'/>
                    </div>
                </Carousel>
            </div>

        </section>
    );
}

export default History;