import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import castleIntroVideo from '../assets/videos/castleIntro.mp4';
import Loader from './Loader';
import styles from './IntroVideo.module.css';
import Home from './Home';
import { Navigate } from 'react-router-dom';

const IntroVideo = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [isPlaying, setIsPlaying] = useState(true);

    const videoEndHandler = () => {
        setIsPlaying(false);
    }

    return (
        <>
        {isLoading && <Loader />}
                <AnimatePresence>
                    {isPlaying && <motion.div 
                        className={styles['intro-video-container']}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{opacity: 0}}
                    >
                        <video
                            className={styles['intro-video']}
                            autoPlay
                            muted
                            src={castleIntroVideo}
                            onClick={(e) => e.target.play()}
                            preload='auto'
                            type='video/mp4'
                            onCanPlayThrough={() => {
                                setIsLoading(false)
                            }}
                            onEnded={videoEndHandler}
                            
                        />
                    </motion.div>}
                </AnimatePresence>
                {!isPlaying && <Navigate to='/home' />}
            </>
    )
}

export default IntroVideo;