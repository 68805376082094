import StartSection from "./StartSection";
import About from "./About";
import ContactUs from "./ContactUs";
import PastSponsers from "./PastSponsers/sponser";
import Navbar from "./Navbar";
import Foooter from "./Footer";
import History from "./History";
import NewSponsors from './NewSponsors';

const Home = () => {
  return (
    <>
      <StartSection />
      <Navbar />
      <About />
      <History />
      <NewSponsors />
      <PastSponsers />
      <ContactUs />
      <Foooter />
    </>
  );
};

export default Home;
