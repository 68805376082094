import styles from "./ContactUs.module.css";
import {motion} from 'framer-motion';

const ContactUs = () => {
  return (
    <motion.section className={styles["page-section"]} id="contact"
      initial={{ opacity: 0, x: '100vw', y: '-50%' }}
      animate={{ opacity: 1, x: 0, y: '-50%' }}
      transition={{ duration: 1 }}
    >
      <span className={styles['line__above']}></span>
      <div className={styles["links-container"]}>
          <a href='https://www.instagram.com/resonanznsut/' target='_blank' rel='noreferrer'><i class="fab fa-instagram"></i></a>
          <a href='mailto:resonanznsut@gmail.com' target='_blank' rel='noreferrer'><i class="fas fa-envelope"></i></a>
          <a href='https://www.facebook.com/resonanznsut' target='_blank' rel='noreferrer'><i class="fab fa-facebook"></i></a>
      </div>
      <span className={styles['line__below']}></span>
    </motion.section>
  );
};

export default ContactUs;
