import styles from './About.module.css';
import aboutImg from '../assets/images/about.png';

const About = () => {
    return (
        <section className={styles['page-section']} id='about'>
            <div className={styles['text-container']}>
                <h1 className={styles['about-title']}>About Resonanz</h1>
                <p className={styles['about-text']}>
                    Resonanz, Netaji Subhas University of Technology's (NSUT) (previously NSIT, University of Delhi) annual intra-college cultural festival has been a proud tradition and an event to look forward to for every NSUTian. A three-day cultural extravaganza which includes competitions and programs ranging from fashion shows to robotics, Resonanz is a breath of fresh air and a much needed shot of high octane energy, with attendance and footfall estimated close to 11,000. A wide range of artists from Shankar Ehsan Loy, Kailash Kher, Bohemia, Nucleya, Shaan, Mrunali thakur and many more have lined-up to perform previously and the event has been covered en masse by the media.
                </p>
            </div>
            <div className={styles['img-container']}>
                <img 
                    src={aboutImg}
                    alt="castle"
                    className={styles['castle-img']}
                />
            </div>
        </section>
    )
}

export default About;