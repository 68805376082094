import React, { useState } from "react";

import creseve from '../images/creseve.jpeg'
import fortuna from '../images/fortuna.png'

import shadesofarabiannights from '../images/shadesofarabiannights.png'
import chessroulette from '../images/chessroulette.png'
import mussletussle from '../images/mussletussle.png'
import scribblejin from '../images/scribblejinn.png'
import wiepitchers from '../images/wiepitchers.png'
import jaaduimela from '../images/jaaduimela.png'
import throttleparadise from '../images/throttleparadise.png'
import nooreibaarat from '../images/nooreibaarat.png'
import encanto from '../images/encanto.png'
import magicopoly from '../images/magicopoly.jpeg'
import clueless from '../images/clueless.png'
import inaugration from '../images/inaugration.jpeg'

import creslogo from '../soclogos/creslogo.png'
import feslogo from '../soclogos/feslogo.jpg'
import shatranjlogo from '../soclogos/shatranjlogo.jpg'
import canvaslogo from '../soclogos/canvaslogo.png'
import tdrlogo from '../soclogos/tdrlogo.jpg'
import ieeelogo from '../soclogos/ieeelogo.jpeg'
import rotaractlogo from '../soclogos/rotaractlogo.png'
import areslogo from '../soclogos/areslogo.png'
import aagaaz from '../soclogos/aagaaz.png'
import venatus from '../soclogos/venatus.png'
import ecelllogo from '../soclogos/ecelllogo.png'
import asnlogo from '../soclogos/asnlogo.png'
import resonanzteam from '../soclogos/resonanzteam.jpeg'

import "./DummyCard2.css";

function DummyCard1() {

    return (
        <>
            <div className='Card-Container'>
                
            <div className='Card'>
                    <div className='Description'>
                        <img src={resonanzteam} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Crescendo Eve</h3>
                            <p>Main Audi/Apj 11</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={inaugration} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <p className='Registration-Misc'>No Registration</p>
                        <p>9:00am - 11:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={creslogo} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Crescendo Eve</h3>
                            <p>Main Audi/Apj 11</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={creseve} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <p className='Registration-Misc'>No Registration</p>
                        <p>11:00am - 6:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={ieeelogo} className='white-logos' alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>IEEE NSUT</h3>
                            <p>Smart Block 1</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={wiepitchers} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1i9DSPZd2XTB9zEhpYXDgRWuh60WDCtPv70t_u06LY04/edit?usp=sharing_eip_se_dm&ts=63369e18'><button className='Registration-Link'>Register Here!</button></a>
                        <p>12:00pm - 5:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={venatus} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Venatus</h3>
                            <p>Nescii Lawn 1</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={encanto} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1pKtwykIIp0q6PaH-Ljle7L4dGdNrq1x0kV6_yzQcm70/edit?usp=sharing_eil_se_dm&ts=633588f3'><button className='Registration-Link'>Register Here!</button></a>
                        <p>11:00am - 6:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={feslogo} className='white-logos' alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>FES</h3>
                            <p>Smart Block 2</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={fortuna} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1RJJziw7W9hh27P864NXR5qV_ww1HdrnsOJgwiqQga4w/edit?usp=sharing_eil_se_dm&ts=63372df8'><button className='Registration-Link'>Register Here!</button></a>
                        <p>11:30am - 2:30pm</p>
                    </div>
                </div>
              
                <div className='Card'>
                    <div className='Description'>
                        <img src={canvaslogo} className='white-logos' alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>CANVAS</h3>
                            <p>Nescii Parking</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={shadesofarabiannights} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1nVdIz5F9eJFsWubMN1Ef-sagMTeZXz9VRWD9Fn-904Y/edit?usp=sharing_eil_se_dm&ts=6335c1fb'><button className='Registration-Link'>Register Here!</button></a>
                        <p>12:00pm - 4:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={aagaaz} className='white-logos' alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Aagaaz</h3>
                            <p>Smart Block 2</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={nooreibaarat} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1jgrKZRoqnurRF68CnuNtwuNjBNl8uS36hcTlI7DFi98/edit?usp=sharing_eip_se_dm&ts=63369e71'><button className='Registration-Link'>Register Here!</button></a>
                        <p>3:00pm - 6:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={shatranjlogo} className='white-logos' alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Shatranj</h3>
                            <p>Block-6 Classroom</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={chessroulette} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://forms.gle/CyQgr25D3bcmFeg77'><button className='Registration-Link'>Register Here!</button></a>
                        <p>12:00pm - 4:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={tdrlogo} className='white-logos' alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>TDR</h3>
                            <p>Nescii Lawn</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={mussletussle} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/d/e/1FAIpQLSeV3YapEK5OX20xtQE0dk5NVRRVjcMBx9xG67zftEbFACTsPw/viewform'><button className='Registration-Link'>Register Here!</button></a>
                        <p>11:00am - 6:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={rotaractlogo} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Rotaract + Axiom</h3>
                            <p>Smart Block 3</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={scribblejin} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1J3VhuYU0VhQs0JhxCXpjSewZdOA1nBKXWiTs4sLYhdE/edit?usp=sharing_eip_se_dm&ts=6336d76f'><button className='Registration-Link'>Register Here!</button></a>
                        <p>1:00pm - 5:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={asnlogo} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>Algorithm Society</h3>
                            <p>Admin</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={clueless} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <p className='Registration-Misc'>No Registration</p>
                        <p>3:00pm - 5:00pm</p>
                    </div>
                </div>


                <div className='Card'>
                    <div className='Description'>
                        <img src={ecelllogo} alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>E-Cell</h3>
                            <p>Amul Grounds</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={magicopoly} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/d/1iPd7B1KMon4t2ttl-DaK5KY49ltnk9pqGgmfGLeIfiw/edit'><button className='Registration-Link'>Register Here!</button></a>
                        <p>1:00pm - 5:00pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={areslogo} className='white-logos' alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>ARES Robotics</h3>
                            <p>Academic Block Lawns</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={jaaduimela} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1oMeZCh9ATJiowd2LasgXiCT96SqfTszU9pAUzwgp3d4/edit?usp=sharing_eil_se_dm&ts=633709fa'><button className='Registration-Link'>Register Here!</button></a>
                        <p>12:30pm - 3:30pm</p>
                    </div>
                </div>

                <div className='Card'>
                    <div className='Description'>
                        <img src={tdrlogo} className='white-logos' alt='' style={{ height: "40px", width: "40px", }} />
                        <div className='Description-Text'>
                            <h3>TDR</h3>
                            <p>Smart Block 4</p>
                        </div>
                    </div>

                    <div className='Poster_Container'>
                        <img src={throttleparadise} alt='' style={{ height: "240px", width: "100%" }} />
                    </div>

                    <div className='Footer-Part'>
                        <a target="_blank" href='https://docs.google.com/forms/u/7/d/1QeyGhG2j6GeSKoIOXmY27pldPSWJp2K2pbuuVjXL7vA/edit?usp=sharing_eip_se_dm&ts=6337d373'><button className='Registration-Link'>Register Here!</button></a>
                        <p>11:00am - 6:00pm</p>
                    </div>
                </div>


            </div>

        </>
    );
}

export default DummyCard1;
